<template>
    <div>
        <b-card>
            <div v-if="$hasPermissions($permissions.EditPromotion)">
                <div class="mb-2" style="font-weight: bold;">
                    Vnesi nov promocijski artikel
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <b-form-group label="BBIS ID">
                            <b-form-input v-model="bbisID"></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <b-form-group label="Število kuponov">
                            <b-form-input v-model="numberOfCoupons" type="number"></b-form-input>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-4">
                        <b-form-group label="Cena">
                            <b-form-input v-model="price" type="number"></b-form-input>
                        </b-form-group>
                    </div>


                    <div class="col-12 col-md-6">
                        <b-form-group label="Velja od">
                            <date-picker v-model="startTime" mode="dateTime" is24hr>
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input
                                        class="custom-timepicker border rounded focus:outline-none focus:border-blue-300"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                    />
                                </template>
                            </date-picker>
                        </b-form-group>
                    </div>

                    <div class="col-12 col-md-6">
                        <b-form-group label="Velja do">
                            <date-picker v-model="endTime" mode="dateTime" is24hr>
                                <template v-slot="{ inputValue, inputEvents }">
                                    <input
                                        class="custom-timepicker border rounded focus:outline-none focus:border-blue-300"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                    />
                                </template>
                            </date-picker>
                        </b-form-group>
                    </div>
                </div>


                <div class="d-flex justify-content-end align-items-end">
                    <div>
                        <div style="color: red;" v-if="productNotExists">
                            Izdelek z tem ID-jem neobstaja.
                        </div>
                        <b-button class="mr-2" style="height: 40px !important;" @click="checkProduct">Preveri, če izdelek obstaja</b-button>
                    </div>
                    <b-button style="height: 40px !important;" :disabled="!canAddPromotion" @click="addPromotion">Dodaj promocijo</b-button>
                </div>
            </div>

            <Table ref="table" class="mt-1" route="/api/management/v1/promotions/" :fields="fields" :fromElastic="false">
                <template #cell(main_photo)="row">
                    <b-img v-if="row.item.main_photo !== ''" fluid  :src="'/api/management/v1/promotions/product_image/1000_' + row.item.main_photo" style="width: 100%; max-height: 70px; object-fit: contain;" alt="Image"/>
                </template>

                <template #cell(start_date)="row">
                    {{getDate(new Date(row.item.start_date))}} {{getHours(new Date(row.item.start_date))}}
                </template>

                <template #cell(end_date)="row">
                    {{getDate(new Date(row.item.end_date))}} {{getHours(new Date(row.item.end_date))}}
                </template>

                <template #cell(price)="row">
                    {{row.item.price/100}}€
                </template>

                <template #cell(actions)="row" v-if="$hasPermissions($permissions.EditPromotion)">
                    <div class="button-grid">
                        <!-- <b-button variant="primary"  :href="'/soseska/' + row.item.id + '?preview=true'" target="_blank"><fa icon="newspaper"/></b-button> -->
                        <b-button variant="danger" @click="deletePromotion(row.item.id)"><fa icon="trash"/></b-button>
                    </div>
                </template>
            </Table>


            <b-modal id="productModal" title="Izdelek za promocijo">
                <div class="d-flex justify-content-center" v-if="this.newProduct">
                    <div>
                        <div style="font-weight: bold; font-size: 15px;">
                            {{this.newProduct.description_1}}
                        </div>
                        <div class="d-flex justify-content-center mt-5">
                            <img style="height: 200px;" :src="'/api/management/v1/promotions/product_image/1000_' + this.newProduct.main_picture" />
                        </div>
                    </div>
                </div>
                <template #modal-footer="{ ok, close}">
                    <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                        <div style="font-weight: bold;">
                            Je to pravilni izdelek?
                        </div>
                        <div>
                            <b-button variant="success" @click="ok(); canAddPromotion = true">DA</b-button>
                            <b-button class="ml-2" variant="danger" @click="close()">NE</b-button>
                        </div>
                    </div>
                    
                </template>
            </b-modal>
        </b-card>
    </div>
</template>

<script>
    import { DatePicker } from 'v-calendar'
    import { BCard, BFormInput, BFormGroup, BButton, BModal, BImg } from 'bootstrap-vue'
    import Table from '@/views/Components/Table.vue'

    export default {
        components:{
            BCard,
            BFormInput,
            BFormGroup,
            DatePicker,
            Table,
            BButton,
            BModal,
            BImg
        },
        data() {
            return {
                bbisID: '',
                startTime: '',
                endTime: '',
                numberOfCoupons: null,
                fields: [
                    { key: 'main_photo', label: 'Slika', sortable: true, class: 'text-center' },
                    { key: 'title', label: 'Naslov', sortable: true, class: 'text-center title-table-data' },
                    { key: 'start_date', label: 'Začetek promocije', sortable: true, class: 'text-center' },
                    { key: 'end_date', label: 'Konec promocije', sortable: true, class: 'text-center' },
                    { key: 'number_of_coupons', label: 'Št. kuponov', sortable: true, class: 'text-center' },
                    { key: 'price', label: 'Cena', sortable: true, class: 'text-center' }
                ],
                canAddPromotion: false,
                newProduct: null,
                showModal: false,
                price: '',
                productNotExists: false
            }
        },
        methods: {
            async deletePromotion(id) {
                try {

                    await this.$http.delete(`/api/management/v1/promotions/${  id}`)

                    this.$printSuccess('Promocija uspešno izbrisana.')

                    setTimeout(() => {
                        this.$refs.table.search()
                    }, 1000)

                } catch (err) {
                    this.$printError('Prislo je do dodajanju promocije.')
                }
            },
            async addPromotion() {

                if (!this.canAddPromotion) {
                    return
                }

                if (this.endTime < this.startTime) {
                    this.$printWarning('Končni datum mora biti večji kot začetni datum.')
                    return
                }

                try {
                    const payload = {
                        main_photo: this.newProduct.main_picture,
                        price: parseInt(parseFloat(this.price) * 100),
                        number_of_coupons: parseInt(this.numberOfCoupons),
                        title: this.newProduct.description_1,
                        start_date: this.startTime,
                        end_date: this.endTime,
                        bbis_id: this.bbisID
                    }

                    await this.$http.post('/api/management/v1/promotions', payload)
                    this.canAddPromotion = false
                    this.$printSuccess('Promocija uspešno dodana.')

                    setTimeout(() => {
                        this.$refs.table.search()
                    }, 1000)

                } catch (err) {
                    this.$printError('Prišlo je do napake pri dodajanju promocije.')
                }
            },
            async checkProduct() {
                try {
                    const res = await this.$http.get(`/api/management/v1/promotions/${  this.bbisID}`)

                    const product = res.data
                    
                    if (product && product.id === this.bbisID) {
                        this.newProduct = product
                        this.$bvModal.show('productModal')
                        this.productNotExists = false
                    } else {
                        this.productNotExists = true
                    }

                } catch (err) {
                    this.$printError('Prislo je do preverjanju produkta.')
                }
            },
            getDate(date) {
                return `${date.getDate() }.${  date.getMonth() + 1  }.${  date.getFullYear()}`
            },
            getHours(date) {
                const minutes = date.getMinutes()
                let stringMinutes = ''

                if (minutes < 10) {
                    stringMinutes = `0${  minutes}`
                } else {
                    stringMinutes = minutes
                }

                return `${date.getHours()  }:${stringMinutes}`
            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditPromotion)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    
    }
</script>

<style scoped>
    .custom-timepicker{
        width: 100%;
        height: 38px;
        padding-left: 14px;
    }
</style>